var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-media',{staticClass:"mb-2"},[_c('h3',{staticClass:"mb-2"},[(_vm.itemData.id === undefined)?_c('span',[_vm._v("Save Record")]):_vm._e(),(_vm.itemData.id !== undefined && this.$hasPermission('modifying-rates'))?_c('span',[_vm._v("Edit Record")]):_vm._e()])]),_c('validation-observer',{ref:"formRules",attrs:{"tag":"form"}},[_c('b-form',{ref:"registerForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingCode"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":true,"maxlength":100},model:{value:(_vm.itemData.SettingCode),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingCode", $$v)},expression:"itemData.SettingCode"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1)],1),((_vm.itemData.SettingCode == 'SelfPaWT' || _vm.itemData.SettingCode == 'SelfpayAS') == false)?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Package"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":100},model:{value:(_vm.itemData.SettingName),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingName", $$v)},expression:"itemData.SettingName"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,2760638876)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Price fee"}},[_c('validation-provider',{attrs:{"rules":"required","name":"base each way"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"price","raw":false,"options":_vm.options.numeral,"state":errors.length > 0 ? false : null},model:{value:(_vm.itemData.SettingValue),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingValue", $$v)},expression:"itemData.SettingValue"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),((_vm.itemData.SettingCode == 'SelfPaWT' || _vm.itemData.SettingCode == 'SelfpayAS') == false)?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"From miles"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"SettingDistance1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"5","disabled":_vm.inhabilitar === true},model:{value:(_vm.itemData.SettingDistance1),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingDistance1", $$v)},expression:"itemData.SettingDistance1"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,301166090)})],1)],1):_vm._e(),((_vm.itemData.SettingCode == 'SelfPaWT' || _vm.itemData.SettingCode == 'SelfpayAS') == false)?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"To miles"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"SettingDistance2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"5","disabled":_vm.inhabilitar === true},model:{value:(_vm.itemData.SettingDistance2),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingDistance2", $$v)},expression:"itemData.SettingDistance2"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,542420265)})],1)],1):_vm._e(),((_vm.itemData.SettingCode == 'SelfPaWT' || _vm.itemData.SettingCode == 'SelfpayAS') == false)?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Trip type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"40","disabled":true},model:{value:(_vm.itemData.SettingType),callback:function ($$v) {_vm.$set(_vm.itemData, "SettingType", $$v)},expression:"itemData.SettingType"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,803161484)})],1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-right"},[(_vm.inhabilitar === false && _vm.itemData.id === undefined)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":_vm.inhabilitar === true ? 'primary' : 'primary'},on:{"click":_vm.saveChanges}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Save")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e(),(_vm.inhabilitar === false && _vm.itemData.id !== undefined && this.$hasPermission('modifying-rates'))?_c('b-button',{staticClass:"ml-1",attrs:{"variant":_vm.inhabilitar === true ? 'primary' : 'primary'},on:{"click":_vm.saveChanges}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Update")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }